<template>
	<div class="header">
		<div class="img-box"><img src="../assets/appointment/logo.png" /></div>
		<p>{{title}}</p>
		<div class="time">
			<div class="date1">{{date1}}</div>
			<div class="date2">
				<div>{{date2}}</div>
				<div>{{date3}}</div>
			</div>
		</div>
<!-- 		<el-tooltip content="返回首页" placement="top">
			<i class="el-icon-monitor" @click="goHome"></i>
		</el-tooltip> -->
	</div>
</template>

<script>
	import jutils from 'jutils-src'

	export default {
		props: ['title'],
		data() {
			return {
				nowDate: new Date()
			}
		},
		computed: {
			date: function() {
				return jutils.formatDate(this.nowDate, 'YYYY-MM-DD HH:ii 周W')
			},
			date1: function() {
				return this.date.split(' ')[0]
			},
			date2: function() {
				return this.date.split(' ')[1]
			},
			date3: function() {
				return this.date.split(' ')[2]
			}
		},
		methods: {
			goHome() {
				localStorage.setItem('active_index', '/main')
				this.$router.push('/main')
			}
		}
	}
</script>

<style>
	.header {
		height: 122px;
		background-color: #f08200;
		color: white;
		align-items: center;
		display: flex;
		border-bottom: 10px solid black;
	}

	.img-box {
		width: 280px;
		height: 100%;
		background-color: white;
		display: flex;
		align-items: center;
		padding: 0 60px;
		border-left: 10px solid #d9300a;
	}

	.img-box>img {
		width: 100%;
	}

	.header>p {
		flex: 1;
		text-align: left;
		font-size: 40px;
		margin-left: 30px;
		font-weight: bold;
		letter-spacing: 4px;
	}

	.time {
		display: flex;
		align-items: center;
		font-style: italic;
		margin-right: 30px;
	}

	.date1 {
		font-size: 34px;
		padding-right: 14px;
	}

	.date2 {
		text-align: right;
	}

	.date2>div:nth-of-type(1) {
		font-size: 14px;
		line-height: 14px;
	}

	.date2>div:nth-of-type(2) {
		font-size: 16px;
		line-height: 16px;
	}

	.el-tooltip {
		margin-right: 20px;
	}
	.el-icon-monitor{
		font-size: 22px;
	}
</style>
