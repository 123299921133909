<template>
	<div class="content-style">
		<p class="title">{{title}}</p>
		<slot></slot>
		<img src="../assets/appointment/border-top-left.png" class="image-d image1" />
		<img src="../assets/appointment/border-top-right.png" class="image-d image2" />
		<img src="../assets/appointment/border-bottom-right.png" class="image-d image3" />
		<img src="../assets/appointment/border-bottom-left.png" class="image-d image4" />
	</div>
</template>

<script>
	export default {
		props: ['title'],
		data() {
			return {

			}
		}
	}
</script>

<style scoped>
	.content-style {
		box-sizing: border-box;
		background-color: rgba(255, 255, 255, .1);
		border: 1px solid rgba(240, 130, 0, .3);
		box-shadow: inset 0 0 6px rgba(240, 130, 0, .3);
		position: relative;
	}

	.title {
		display: block;
		font-size: 26px;
		font-weight: bold;
		color: #f08200;
		height: 58px;
		line-height: 54px;
		border-bottom: 2px solid rgba(0, 0, 0, .2);
		text-align: center;
		background-color: rgba(0, 0, 0, .15);
	}

	.image-d {
		display: inline-block;
		position: absolute;
		opacity: .4;
	}

	.image1 {
		top: -10px;
		left: -10px;
	}

	.image2 {
		top: -10px;
		right: -10px;
	}

	.image3 {
		bottom: -10px;
		right: -10px;
	}

	.image4 {
		bottom: -10px;
		left: -10px;
	}
</style>
