<template>
  <div id="top">
    <HeaderStyle title='访客登记预约系统' />
    <div class="section content">
      <ContentStyle class="right-cont top-day" title="今日访客">
        <el-table :data="curData" style="width: 100%;">
          <el-table-column prop="username" label="姓名" align="center">
          </el-table-column>
          <el-table-column prop="interviewee" label="受访人" align="center">
          </el-table-column>
          <el-table-column prop="phone" label="联系方式" align="center">
          </el-table-column>
          <el-table-column prop="reason" label="事由" align="center">
          </el-table-column>
          <el-table-column prop="visitTimeStart" label="时间起" align="center" width="280">
          </el-table-column>
        </el-table>
        <ul class="page-box">
          <li v-for="(item, index) in page" :class="curPage===index+1?'page-cur':''" :key="index"></li>
        </ul>
      </ContentStyle>
      <el-input class="my-input" ref="myInput" v-model="searchData" @change="search"></el-input>
      <ContentStyle class="read-id" title="操作栏">
        <div class="action">
          <el-button type="primary" :plain="true" @click="getIdCard">身份证读取</el-button>
        </div>
      </ContentStyle>
    </div>
    <el-dialog title="预约信息" :visible.sync="dialog" width="30%" center class="my-dialog">
      <div class="item">
        <ul class="list">
          <li><label>姓名:</label>
            <p>{{pData.username}}</p>
          </li>
          <li><label>手机号:</label>
            <p>{{pData.phone}}</p>
          </li>
          <li><label>来访时间起:</label>
            <p>{{pData.visitTimeStart}}</p>
          </li>
          <li><label>来访时间止:</label>
            <p>{{pData.visitTimeEnd}}</p>
          </li>
          <li><label>来访事由:</label>
            <p>{{pData.reason}}</p>
          </li>
          <li><label>来访人数:</label>
            <p>{{pData.visitorNum}}</p>
          </li>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialog = false; getList();">取 消</el-button> -->
        <el-button class="qr" type="primary" @click="dialog = false; getList();">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import $ from 'jquery'
  import axios from 'axios'
  import HeaderStyle from '../../components/HeaderStyle.vue'
  import ContentStyle from '../../components/ContentStyle.vue'

  export default {
    components: {
      ContentStyle,
      HeaderStyle
    },
    data() {
      return {
        pData: {},
        dialog: false,
        searchData: '',
        tableData: [],
        timer: null,
        timerInit: null,
        listLength: 8, // 行
        curPage: 1,
        intervalTime: 2000, // 轮播时间
      }
    },
    computed: {
      curData: function() {
        return this.tableData.slice((this.curPage - 1) * this.listLength, this.curPage * this.listLength)
      },
      page() {
        return Math.ceil(this.tableData.length / this.listLength)
      }
    },
    mounted() {
      this.$refs.myInput.focus()
      this.getList()
      this.setCurPage()
      this.timerRefresh = setInterval(() => {
        this.getList()
      }, 5000)
      this.timerInit = setInterval(() => {
        location.reload()
      }, 86400000)
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
      clearInterval(this.timerRefresh)
      this.timerRefresh = null
      clearInterval(this.timerInit)
      this.timerInit = null
    },
    methods: {
      getIdCard() {
        var that = this
        $.support.cors = true
        $.ajax({
          dataType: "json",
          type: "get",
          url: "http://127.0.0.1:8088/ReadCard", //接口服务器地址  参数: Fp=1读证内指纹，PhotoQuality 身份证头像质量，cardImg=1获取身份证正反面图片
          async: false,
          //contentType: "application/x-www-form-urlencoded; charset=utf-8",
          success: function(data) {
            //成功执行
            that.searchData = data.cardno
            that.search()
          },
          error: function(e) {
            //失败执行
            alert("失败执行")
          }
        })
      },
      search() {
        this.$axios.post('https://trace.csgpower.com.cn:8089/BMS/visitor-record/checkByCode?code=' + this.searchData).then(res => {
          if (res.data.code === 200) {
            this.pData = res.data.data
            this.dialog = true
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error',
              offset: 300
            })
          }
          this.searchData = ''
          this.$refs.myInput.focus()
        })
      },
      getList(id) {
        this.$axios.post('https://trace.csgpower.com.cn:8089/BMS/visitor-record/findListOfToday').then(res => {
          this.tableData = res.data.data
          this.$refs.myInput.focus()
        }).catch(err => {
          this.$refs.myInput.focus()
        })
      },
      setCurPage: function() {
        this.timer = setInterval(() => {
          this.curPage++
          if (this.curPage > this.page) {
            this.curPage = 1
          }
        }, this.intervalTime)
      }
    }
  }
</script>

<style scoped>
  #top {
    height: 100%;
    background: #170c02 url('../../assets/appointment/bg.png') center center/100% 100%;
    overflow: hidden;
  }

  .content {
    margin: 30px;
    position: relative;
    display: flex;
    height: calc(100% - 192px);
  }

  .left-cont {
    width: 50%;
    height: 100%;
    margin-right: 30px;
  }

  .top-week,
  .top-month {
    box-sizing: border-box;
    height: calc(50% - 15px);
    background-color: rgba(255, 255, 255, .1);
    border: 1px solid rgba(240, 130, 0, .3);
    box-shadow: inset 0 0 6px rgba(240, 130, 0, .3);
    position: relative;
  }

  .top-week {
    margin-bottom: 30px;
  }

  .right-cont {
    height: 100%;
    position: relative;
    box-sizing: border-box;
    flex: 1;
    background-color: rgba(255, 255, 255, .1);
    border: 1px solid rgba(240, 130, 0, .3);
    border-radius: 3px;
    box-shadow: inset 0 0 6px rgba(240, 130, 0, .3);
  }

  .no-list {
    position: relative;
    width: 100%;
    height: calc(100% - 60px);

  }

  .no-list>li {
    display: flex;
    flex-flow: column;
    position: absolute;
    text-align: center;
    /* padding-top: 2%; */
    height: 100%;
    justify-content: center;
  }

  .name-style {
    font-size: 20px;
    line-height: 38px;
    font-weight: bold;
  }

  .no-list>li:nth-of-type(1) {
    width: 24%;
    /* top: 4%; */
    left: 38%;
  }

  .no-list>li:nth-of-type(1)>.name-style {
    color: #fadd9e;
  }

  .no-list>li:nth-of-type(2) {
    width: 22%;
    top: 2%;
    left: 10%;
  }

  .no-list>li:nth-of-type(2)>.name-style {
    color: #bad6f5;
  }

  .no-list>li:nth-of-type(3) {
    width: 22%;
    top: 2%;
    right: 10%;
  }

  .no-list>li:nth-of-type(3)>.name-style {
    color: #f5c5ae;
  }

  .no-img-box {
    position: relative;
    z-index: 99;
  }

  .no-img-box>img {
    width: 100%;
  }

  .num-style {
    font-size: 18px;
    color: white;
  }

  .no-img-cont {
    position: absolute;
    top: 15.5%;
    left: 12%;
    width: 77.5%;
    height: 77.5%;
    background: white no-repeat center 36%/100% auto;
    border-radius: 50%;
    z-index: -1;
    /* background-image: url(../../assets/img/top/woman.jpg); */
  }

  #top>>>.el-table__empty-text {
    color: #ffd2c4;
  }

  #top>>>.el-table,
  #top>>>.el-table th,
  #top>>>.el-table tr {
    background: transparent;
  }

  #top>>>.el-progress {
    padding: 0 0.025rem;
  }

  #top>>>.el-table {
    color: #ffd2c4;
  }

  #top>>>.el-table thead {
    color: #ff5d2b;
  }

  #top>>>.el-table th.is-leaf {
    border-bottom: 1px solid rgba(0, 0, 0, .4);
  }

  #top>>>.el-table td {
    border-bottom: 1px solid rgba(0, 0, 0, .4);
  }

  #top>>>.el-table .el-table__body-wrapper tr:last-child td {
    border-bottom: 1px solid rgba(0, 0, 0, .4);
  }

  #top>>>.el-table::before {
    height: 0;
    background-color: rgba(0, 0, 0, 1) !important;
  }

  #top>>>.el-table td,
  #top>>>.el-table th {
    font-size: 20px;
  }

  #top>>>.el-table th {
    line-height: 48px;
  }

  .no-img {
    width: 36px;
  }

  #top>>>.el-table--enable-row-hover .el-table__body tr:hover>td {
    background-color: rgba(255, 255, 255, .2);
  }

  .page-box {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 20px;
  }

  .page-box>li {
    width: 10px;
    height: 2px;
    background-color: rgba(240, 130, 0, .3);
    margin: 0 5px;
  }

  .page-box>li.page-cur {
    background-color: rgba(240, 130, 0, .9);
  }

  .my-input {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .my-input>>>.el-input__inner {
    height: 100%;
    background-color: transparent;
    border: none;
    color: transparent;
  }

  .my-dialog>>>.el-dialog {}

  .item {
    color: #646464;
    font-size: 28rpx;
    border-radius: 3px;
    /* margin: 10px 20px 20px; */
    background-color: transparent;
  }

  .list {
    padding: 1.2rem;
    /* margin-bottom: 2rem; */
    background: #fff url('../../assets/appointment/dialog-bg.png') no-repeat center center/120% auto !important;
    box-shadow: 0 2px 4px #dedede;
    border: 1px solid #F9F9F9;
  }

  .list>li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0;
  }

  .list>li>label {
    margin-right: 8px;
  }

  .list>li>p {
    color: #323232;
  }

  .el-button--primary {
    color: white;
    background-color: #f08200;
    border-color: #f08200;
  }
  
  .read-id {
    width: 180px;
    z-index: 999;
    margin-left: 20px;
  }
  .action {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .action .el-button {
    margin-top: 30px;
  }
  .qr {
    width: 100%;
  }
</style>
